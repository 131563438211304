import Axios from 'axios'
import cacheData from 'memory-cache'
import Env from 'Consts/env'
import Cookies from 'js-cookie'

/**
 *
 * @param {Object} params
 * @returns
 */
export const GetSelf = async ({ Authorization }) => {
  try {
    const result = await Axios({
      method: 'GET',
      url: `/api/profile?Authorization=${Authorization}`,
    })
    return result
  } catch (error) {
    throw new Error('Error Something')
  }
}

/**
 *
 * @param {Object} params
 * @returns
 */
export const RefreshToken = async ({ Authorization }) => {
  try {
    const result = await Axios({
      method: 'POST',
      url: `/api/refreshToken?Authorization=${Authorization}`,
    })
    return result
  } catch (error) {
    throw new Error('Error Something')
  }
}

/**
 *
 * @param {Object} params
 * @returns
 */
export const PostComment = async (params) => {
  try {
    const result = await Axios({
      method: 'POST',
      url: '/api/comment/',
      data: params,
      headers: { 
        Authorization: Cookies.get('token') ? Cookies.get('token') : params?.Authorization || null,
      },
    })
    return result
  } catch (error) {
    throw new Error('Error Something')
  }
}

/**
 *
 * @param {Object} params
 * @returns
 */
export const GetComments = async (params) => {
  try {
    const result = await Axios({
      method: 'GET',
      url: `/api/getComments?post_id=${params.post_id}&next_id=${params.next_id}&limit=${params.limit}`,
    })
    return result
  } catch (error) {
    throw new Error('Error Something')
  }
}

export const fetchWithCache = async (url, time, intrv) => {
  const value = cacheData.get(url)
  if (value) {
    return value
  } else {
    const hours = typeof time != 'undefined' ? time : 1
    const interval = typeof intrv != 'undefined'? 50000 : hours * 1000 * 60 * 60
    const res = await fetch(url, { headers: { ...Env.HEADERS } })

    const data = await res.json()

    if (data && typeof data !== 'undefined' && typeof data.data !== 'undefined' && data.meta.code === 200) {
      cacheData.put(url, data, interval)
    }
    return data
  }
}

export const getBanner = async (params) => {

	try {

		const options = {
			method: "GET",
			headers: {
				placement: params.placement,
				device: params.device,
				key: process.env.NEXT_PUBLIC_KEY_API,
			},
		};

		const response = await fetch(`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getBanner`, options);
		const data = await response.json();

		return data;

	} catch (err) {

		throw new Error("Error fetch data banner", err);

	}

};