/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

const Banner = ({ ...props }) => {

	const prop = props;

	const [Dataads, setDataads] = useState("");
	const [IsPLaceholder, setIsPLaceholder] = useState(true);

	const handleData = (e) => {

		setDataads(e);

		setIsPLaceholder(false);

	};

	useEffect(() => {

		const site = prop.is_mobile ? "mobile" : "desktop";
		const urls = process.env.NEXT_PUBLIC_NODE_ENV === "production"
      ? 'https://detikads.femaledaily.com/static/ads/femaledaily/'
      : 'https://detikads.femaledaily.com/static/ads/femaledaily/staging_femaledaily/'
		fetch(`${urls + site}/${prop.name}.txt?${Math.random()}`)
			.then((response) => response.text())
			.then((data) => {

				data !== "" || data !== " " || data !== "  "	|| data !== "   " ? handleData(data)
					: null;

			})
			.catch((error) => {

				setIsPLaceholder(false);

				console.error(`onRejected data: ${error}`);

			});

	}, []);

	useEffect(() => {

		try {

			reviveAsync["0cceecb9cae9f51a31123c541910d59b"].refresh();

		} catch (e) {

			if (IsPLaceholder) {

				setIsPLaceholder(false);

			}

		}
		try {

			if (Dataads && Dataads !== " ") {

				refreshSlotDfp(prop.name);

			}

		} catch (e) {

			console.error("error refreshSlotDfp: ", e);

		}

	});
	useEffect(() => {
		setTimeout(() => {
			const elements = document.getElementsByClassName(prop.class_name);
			if (elements.length > 0 && document.getElementsByClassName(prop.class_name)[0].offsetHeight === 0) {
				elements[0].remove()
			} 
		}, 1500);
	}, [Dataads === ""])
	
	return (
		<>
			{IsPLaceholder ? (
				<div id={prop.component_id || ""} className={prop.class_name || ""}>
					<img
						src={`https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/ads/${prop.placeholder}.png`}
						alt="banner-detik"
					/>
				</div>
			) : null}
			{
				Dataads &&
				<div
					id={prop.component_id}
					className={prop.class_name || ""}
					dangerouslySetInnerHTML={{
						__html: Dataads,
					}}
				/>
			}
			<style jsx>{`
          .bottomframe {
            bottom: -4px;
            left: 0px;
            position: fixed;
            right: 0px;
            text-align: center;
            z-index: 3;
          }
          .ads-billboard {
            padding: 20px 0;
          }
        `}</style>
		</>
	);

};

export default Banner;
